const API_URL = 'https://9ncpzlnj87.execute-api.us-east-1.amazonaws.com/qotd-service---user-profile-dev';

class MODEL {
  requestData(axios, path, data) {
    return axios
      .post(API_URL + path, data)
      .then(response => {
        console.log(response);
        if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
          return response.data.body;
        } else {
          alert('Fail to request! Try it again! Reason: ' + response.data.message);
          return null;
        }
      })
      .catch(function(error) {
        console.error(error);
        alert('Fail to request! Try it again!');
      });
  }
  convertId(id, as) {
    if (id === 'test_id') {
      if (as == 'host') {
        id = '6180260340bff54a954edbe5';
      } else if (as == 'join') {
        id = '618428cf054d38aee3a98a3f';
      }
    } else if (id === 'running_test_id') {
      if (as == 'host') {
        id = '618428cf054d38aee3a98a3f';
      } else if (as == 'join') {
        id = '61857aeb8e887ebba3a590ca';
      }
    } else if (id === 'finished_test_id') {
      if (as == 'host') {
        id = '618c2560fb3ade8e56d9df51';
      } else if (as == 'join') {
        id = '618c2560fb3ade8e56d9df51';
      }
    } else if (id === 'tied_test_id') {
      if (as == 'host') {
        id = '618d248b65494b0dcf87fe68';
      } else if (as == 'join') {
        id = '618d248b65494b0dcf87fe68';
      }
    }
    return id;
  }
  updateNickname(axios, as, id, nickname) {
    id = this.convertId(id, as);
    return new Promise(resolve => {
      this.requestData(axios, '/update-nickname', { id, as, nickname }).then(response => {
        console.log(response);
        resolve(response);
      });
    });
  }
  get(axios, as, id) {
    id = this.convertId(id, as);
    return new Promise(resolve => {
      this.requestData(axios, '/get-challenge', { id }).then(response => {
        console.log(response);
        resolve(response);
      });
    });
  }
}

export default new MODEL();
