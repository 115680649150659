<template>
  <section id="challenge-setup" class="text-white d-flex flex-column">
    <div class="commet-background flex-fill pt-4 pb-5 px-4 d-flex flex-column">
      <SectionTitleHeader :title="title" color="general-knowledge" class="pt-5 mb-5" />
      <main class="d-flex flex-column justify-content-center">
        <input
          v-model="nickname"
          @input="$forceUpdate()"
          placeholder="Nickname"
          data-aos="fade-in"
          data-aos-delay="500"
          class="outlined-input text-white mb-5 font-weight-700 input text-center"
          maxlength="10"
        />
      </main>
      <div class="d-flex justify-content-space-between">
        <button type="button" class="btn bg-challenge-mode-dark text-white flex-fill rounded-button --outlined" @click="back" data-aos="fade-right" data-aos-offset="-100">
          Back
        </button>
        <div class="px-2"></div>
        <button :disabled="!validForm" type="button" @click="next" class="btn bg-green-neon flex-fill rounded-button" data-aos="fade-left" data-aos-offset="-100">
          Next
        </button>
      </div>
    </div>
  </section>
</template>
<script>
import SectionTitleHeader from '@/components/SectionTitleHeader.vue';
import challengeModel from '@/models/challenge.model.js';

export default {
  components: { SectionTitleHeader },
  data: () => ({
    nickname: ''
  }),
  computed: {
    title() {
      return this.$route.meta.as == 'host' ? 'New Challenge' : `Join ${this.$store.state.challenge.session_data?.players[0].nickname}'s game`;
    },
    validForm() {
      return this.nickname.length >= 3;
    }
  },
  methods: {
    back() {
      this.$store.commit('setPageTransition', 'back');
      this.$router.push({ name: 'challenge-' + this.$route.meta.as, params: this.$route.params });
    },
    async next() {
      this.$store.commit('setNickname', this.nickname);
      const as = this.$route.meta.as;
      const id = this.$route.params.id;
      const result = await challengeModel.updateNickname(this.axios, as, id, this.nickname);
      if (!result.success) alert('Failed to update your nickname! Try it again later!');
      if (as == 'host') {
        this.$router.push({ name: 'challenge-host-share', params: this.$route.params });
      } else {
        this.$router.push({ name: 'challenge-join-done', params: this.$route.params });
      }
    }
  },
  mounted() {
    if (!this.$store.state.challenge.session_data) {
      this.$router.push({ name: 'challenge-' + this.$route.meta.as, params: this.$route.params });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/custom-vars/_colors.scss';

#challenge-setup {
  background: $gradient-challenge-mode;
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.4);
  min-height: 100%;
  main {
    flex-grow: 2;
  }
  button.--outlined {
    border-color: $green-neon;
  }
}
</style>
